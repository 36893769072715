import { useState, useEffect, useCallback } from 'react';
import ColyseusClient from '../../../Network/ColyseusClient';
import { container } from 'tsyringe';

const TOTAL_SLOTS = 10;
const STORAGE_KEY = 'loadedModels';

interface LoadedModel {
    id: number;
    url: string;
    lessonName: string;
    icon: string;
}

export const useLoadedModels = () => {
    const [loadedModels, setLoadedModels] = useState<LoadedModel[]>([]);
    const [currentModelIndex, setCurrentModelIndex] = useState(-1);
    const colyseus = container.resolve(ColyseusClient);

    useEffect(() => {
        const storedModels = localStorage.getItem(STORAGE_KEY);
        if (storedModels) {
            setLoadedModels(JSON.parse(storedModels));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(loadedModels));
    }, [loadedModels]);

    const addLoadedModel = useCallback((model: LoadedModel) => {
        setLoadedModels((prevModels) => {
            const existingIndex = prevModels.findIndex(
                (m) => m.id === model.id,
            );
            let newModels: LoadedModel[];

            if (existingIndex !== -1) {
                newModels = prevModels.filter(
                    (_, index) => index !== existingIndex,
                );
            } else if (prevModels.length >= TOTAL_SLOTS) {
                newModels = prevModels.slice(1);
            } else {
                newModels = [...prevModels];
            }

            return [...newModels, model];
        });
    }, []);

    const removeModel = useCallback((modelId: number) => {
        setLoadedModels((prevModels) =>
            prevModels.filter((m) => m.id !== modelId),
        );
    }, []);

    const resetAllModels = useCallback(() => {
        setLoadedModels([]);
        updateModel3dViewer('none');
    }, []);

    const updateModel3dViewer = useCallback(
        (modelUrl: string) => {
            colyseus.updateModel3dViewer({
                modelUrl,
                modelVisibility: true,
                isAnimationPlaying: false,
                isModelRotating: false,
                modelRotationDirection: 1,
                modelRotationSpeed: 0,
                modelScale: 0,
            });
        },
        [colyseus],
    );

    const handleChangeModel = useCallback(
        (model: LoadedModel) => {
            updateModel3dViewer(model.url);
        },
        [updateModel3dViewer],
    );

    const handleLoadModels = useCallback(() => {
        if (loadedModels.length > 0) {
            updateModel3dViewer(loadedModels[0].url);
        }
    }, [loadedModels, updateModel3dViewer]);

    return {
        loadedModels,
        addLoadedModel,
        removeModel,
        resetAllModels,
        handleChangeModel,
        handleLoadModels,
        totalSlots: TOTAL_SLOTS,
        currentModelIndex,
        setCurrentModelIndex,
    };
};
