import React, { useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { CiImageOff } from 'react-icons/ci';
import { MdOutlineFileDownload } from 'react-icons/md';
import { container } from 'tsyringe';
import ColyseusClient from '../../../Network/ColyseusClient';
import TranslationService from '../../../Translations/TranslationService';
import { useLoadedModelsContext } from '../Services/LoadedModelsContext';

const LoadedModels = () => {
    const colyseusClient = container.resolve(ColyseusClient);
    const {
        loadedModels,
        removeModel,
        resetAllModels,
        handleChangeModel,
        handleLoadModels,
        totalSlots,
    } = useLoadedModelsContext();
    const [hoveredModelId, setHoveredModelId] = useState(null);
    const emptySlots = totalSlots - loadedModels.length;

    const removeModelFromLoadedModels = (modelId) => {
        removeModel(modelId);
    };

    const handleClick = (model) => {
        handleChangeModel(model);
    };

    if (loadedModels.length === 0) {
        return null;
    }

    return (
        <div className="model3d-selected-models-wrapper">
            <div className="model3d-selected-models-grid">
                {loadedModels.map((model) => (
                    <div
                        key={model.id}
                        className="model3d-grid-item"
                        onClick={() => handleClick(model)}
                        onMouseEnter={() => setHoveredModelId(model.id)}
                        onMouseLeave={() => setHoveredModelId(null)}
                    >
                        <img src={model.icon} alt={model.lessonName} />
                        <span>{model.lessonName}</span>
                        {hoveredModelId === model.id && (
                            <div
                                className="delete-icon-wrapper"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    removeModelFromLoadedModels(model.id);
                                }}
                            >
                                <FaRegTrashAlt className="delete-icon" />
                            </div>
                        )}
                    </div>
                ))}
                {[...Array(emptySlots)].map((_, index) => (
                    <div
                        key={`empty-${index}`}
                        className="model3d-grid-item empty"
                    >
                        <div className="empty-slot">
                            <CiImageOff />
                        </div>
                    </div>
                ))}
            </div>
            <div className="loaded-models-buttons-container">
                <button
                    className="load-selected-models"
                    onClick={handleLoadModels}
                >
                    {TranslationService.translate('vr.load_models')}{' '}
                    <MdOutlineFileDownload />
                </button>

                <button
                    className="loaded-models-reset-models"
                    onClick={resetAllModels}
                >
                    {TranslationService.translate('vr.reset_models')}{' '}
                    <FaRegTrashAlt />
                </button>
            </div>
        </div>
    );
};

export default LoadedModels;
