import React, { useEffect } from 'react';
import PanelHeader from '../3d-viewer-panel/Ui/3d-panel-header';
import TranslationService from '../../Translations/TranslationService';
import { SkyboxView } from './skybox-view';
import './main.css';

const TakeUsToPanelViewer = ({ handleClose, handleUnclick }) => {
    return (
        <div className="popup-wrapper-model-viewer">
            <div className="popup-content-model-viewer">
                <PanelHeader
                    title={TranslationService.translate('vr.take_us_to_title')}
                    description={TranslationService.translate(
                        'vr._take_us_to_description',
                    )}
                    closeButtonHandler={() => {
                        handleClose();
                    }}
                    backButtonHandler={() => {}}
                    backButton={null}
                />

                <SkyboxView />

                {/*<Tabs>*/}
                {/*    <Tab*/}
                {/*        label={TranslationService.translate(*/}
                {/*            'vr.take_us_to.courses',*/}
                {/*        )}*/}
                {/*    >*/}
                {/*        <CoursesView />*/}
                {/*    </Tab>*/}
                {/*    <Tab*/}
                {/*        label={TranslationService.translate(*/}
                {/*            'vr.take_us_to.skyboxes',*/}
                {/*        )}*/}
                {/*    >*/}
                {/*    </Tab>*/}
                {/*</Tabs>*/}
            </div>
        </div>
    );
};

export default TakeUsToPanelViewer;
