import React, { useEffect, useState } from 'react';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import envierments from '../../../Environments/envierments';
import ColyseusClient from '../../Network/ColyseusClient';
import TranslationService from '../../Translations/TranslationService';
import { FaSearch } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

export function SkyboxView() {
    const [allSkyboxes, setAllSkyboxes] = useState([]);
    const [filteredSkybox, setFilteredSkybox] = useState([]);
    const [selectedSkybox, setSelectedSkybox] = useState([]);
    const [showMask, setShowMask] = useState(true);
    const httpClient = container.resolve(AxiosHttpClient);
    const colyseusClient = container.resolve(ColyseusClient);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        let skyboxes = await httpClient.searchSkybox();
        if (skyboxes.data.length > 0) {
            setFilteredSkybox(skyboxes.data);
            setAllSkyboxes(skyboxes.data);
            setShowMask(false);
        }
    };

    const handleSearchInputFilter = (value: string) => {
        const searchByName = allSkyboxes.filter((skybox) =>
            skybox.name.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredSkybox(searchByName);
    };

    const handleSelectSkybox = async (e, skyboxFileId) => {
        const selectedItem = e.currentTarget;
        const selectedItemId = e.currentTarget.dataset.id;

        if (!selectedSkybox.includes(selectedItemId)) {
            selectedSkybox.push(selectedItemId);
            colyseusClient.changeSkybox(String(skyboxFileId));
            selectedItem.classList.add('selected-skybox');
        } else {
            selectedSkybox.splice(selectedSkybox.indexOf(selectedItemId), 1);
            selectedItem.classList.remove('selected-skybox');
        }
    };

    const Loader = () => {
        if (showMask) {
            return (
                <div className="spinner-clip">
                    <ClipLoader
                        color={'#FFF'}
                        loading={showMask}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            );
        }
    };

    return (
        <div className="skybox-panel-list">
            <div className="search-model-3d-container">
                <input
                    type="text"
                    className="search-model-3d"
                    placeholder={TranslationService.translate(
                        'vr.search-model',
                    )}
                    onChange={(e) => handleSearchInputFilter(e.target.value)}
                />
                <div className="search-icon">
                    <FaSearch />
                </div>
            </div>
            {/*<div className="skybox-panel-title">Wybierz skybox:</div>*/}
            {/*<div className="skybox-panel-search">*/}

            {/*</div>*/}

            {/*<div className="collections-model-viewer">*/}
            {/*    {courses.map((course, key) => (*/}
            {/*        <div*/}
            {/*            className="model3d-course"*/}
            {/*            key={key}*/}
            {/*            onClick={(e) => handleShowPanelDetails(e, course.id)}*/}
            {/*        >*/}
            {/*            <img*/}
            {/*                className="model3d-course-icon"*/}
            {/*                src={*/}
            {/*                    course.icon_url ===*/}
            {/*                    '/media/img/default_course_icon.svg'*/}
            {/*                        ? defaultCourseIcon*/}
            {/*                        : envierments.baseURL +*/}
            {/*                          `/file/serve/${course.icon_url}`*/}
            {/*                }*/}
            {/*            />*/}
            {/*            <div className="model3d-course-title" key={course.id}>*/}
            {/*                <p>{course.title}</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*</div>*/}
            <Loader />

            <div className="collections-skybox">
                {/*TODO: Wyświetlone z SEARCH:*/}
                {filteredSkybox &&
                    filteredSkybox.length > 0 &&
                    filteredSkybox.map((skybox) => (
                        <div
                            className="model3d-course skybox-item"
                            data-id={skybox.id}
                            key={skybox.id.toString()}
                            onClick={(e) =>
                                handleSelectSkybox(e, skybox.uploaded_file_id)
                            }
                        >
                            <div className="skybox-item-prev">
                                <img
                                    src={
                                        envierments.baseURL +
                                        `/file/serve/${skybox.uploaded_file_id}`
                                    }
                                    className="img-contain"
                                />
                            </div>
                            <div className="skybox-item-title">
                                {skybox.name}
                            </div>
                        </div>
                    ))}
            </div>

            <div className="skybox-panel-accept-btn">
                {/*<button className="skybox-panel-btn">Wybierz</button>*/}
            </div>
        </div>
    );
}
