import { autoInjectable } from 'tsyringe';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import Resources from '../../Resources';
import { Color, Object3D } from 'three';
import { Block } from 'three-mesh-ui';
import VrScene from '../Three/VrScene';
import IntersectionContainer from '../Controllers/IntersectionContainer';
import Renderer from '../Renderer';

@autoInjectable()
export default class DoorService {
    public constructor(
        public httpClient?: AxiosHttpClient,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
        public renderer?: Renderer,
    ) {}

    public async getClassesSorted() {
        const myClassesAndSubjectList = [];

        if (this.resources.items.user.is_school_student) {
            const classesForUser = await this.getClassesForStudent();

            if (
                classesForUser &&
                classesForUser.hasOwnProperty('data') &&
                classesForUser.data.length > 0
            ) {
                classesForUser.data.forEach((className) => {
                    if (
                        className.hasOwnProperty('subjects') &&
                        className.subjects.length > 0
                    ) {
                        for (let i = 0; i < className.subjects.length; i++) {
                            myClassesAndSubjectList.push({
                                className: className.name,
                                classId: className.id,
                                subjectName: className.subjects[i].name,
                                subjectId: className.subjects[i].id,
                            });
                        }
                    }
                });
            }
        } else if (this.resources.items.user.is_school_teacher) {
            const classesForTeacher = await this.getClassesForTeacher();

            if (
                classesForTeacher &&
                classesForTeacher.hasOwnProperty('data') &&
                classesForTeacher.data.length > 0
            ) {
                classesForTeacher.data.forEach((className) => {
                    myClassesAndSubjectList.push({
                        className: className.school_class_name,
                        classId: className.class_id,
                        subjectName: className.name,
                        subjectId: className.id,
                    });
                });
            }
        }

        return myClassesAndSubjectList;
    }

    public getGroups() {
        if (this.resources.items.user.is_school_teacher) {
            return this.getGroupsForTeacher();
        }
        return this.getGroupsForStudents();
    }

    public getGroupsForStudents() {
        return this.httpClient.instance.get(
            '/api/v2/connections/student/groups',
        );
    }

    public getGroupsForTeacher() {
        return this.httpClient.instance.get(
            'api/v2/connections/my_private_classes',
        );
    }

    public getClassesForStudent() {
        return this.httpClient.instance.get('/api/v2/school/my_classes');
    }

    public getClassesForTeacher() {
        return this.httpClient.instance.get(
            '/api/v2/gradebook/teacher/subjects_list',
        );
    }

    public setDoorTo(
        scene: VrScene,
        roomId: number,
        multiplayer: boolean = false,
        sceneName?: string,
    ) {
        const doorCube = new Object3D();
        const uiDoorElement = new Block({
            width: 1.5,
            height: 2.3,
            backgroundOpacity: 0,
        });
        uiDoorElement.name = 'door';

        uiDoorElement.userData.roomId = roomId;
        uiDoorElement.userData.sceneName = sceneName;

        setTimeout(() => {
            this.intersectionContainer.addObjectToIntersect(uiDoorElement);
        }, 500);

        //@ts-ignore
        uiDoorElement.setupState({
            state: 'idle',
            attributes: {
                backgroundOpacity: 0,
            },
        });

        //@ts-ignore
        uiDoorElement.setupState({
            state: 'hovered',
            attributes: {
                backgroundColor: new Color('blue'),
                backgroundOpacity: 0.5,
            },
            onSet: () => {},
        });
        //@ts-ignore
        uiDoorElement.setupState({
            state: 'selected',
            onSet: async () => {
                if (uiDoorElement.userData.roomId === null) {
                    return;
                }

                //@ts-ignore
                this.renderer.scene.changeScene(
                    scene,
                    uiDoorElement.userData.roomId,
                    multiplayer,
                    sceneName,
                );
            },
            attributes: {
                backgroundColor: new Color('blue'),
                backgroundOpacity: 1,
            },
        });

        doorCube.add(uiDoorElement);
        const object3d = new Object3D();
        object3d.userData.getDoor = () => {
            return uiDoorElement;
        };
        return object3d.add(doorCube);
    }
}
