import { MdArrowBack, MdClose } from 'react-icons/md';

const PanelHeader = ({
    backButton,
    closeButtonHandler,
    backButtonHandler,
    description,
    title,
}) => {
    const BackButton = () => {
        if (backButton) {
            return (
                <button className="back-button" onClick={backButtonHandler}>
                    <MdArrowBack />
                </button>
            );
        }
    };

    return (
        <div className="panel-header">
            <BackButton />
            <div className="panel-title-description">
                <div className="model3d-panel-title">{title}</div>
                <div className="model3d-description">{description}</div>
            </div>
            <button
                onClick={() => closeButtonHandler()}
                className="close-button m-1"
            >
                <MdClose size="2em" />
            </button>
        </div>
    );
};

export default PanelHeader;
