import { EventDispatcher } from 'three';
import { autoInjectable, singleton } from 'tsyringe';

@singleton()
@autoInjectable()
export default class DemoService extends EventDispatcher {
    private _isDemo: boolean = false;
    private demoTimer: number | null = null;
    private readonly DEMO_TIMEOUT = 0.1 * 60 * 1000;
    private _isWebXRAvailable: boolean = true;

    public constructor() {
        super();
    }

    public get isDemo(): boolean {
        return this._isDemo;
    }

    public get isWebXRAvailable(): boolean {
        return this._isWebXRAvailable;
    }

    public setDemoMode(isDemo: boolean): void {
        if (this._isDemo !== isDemo) {
            this._isDemo = isDemo;
            this.dispatchEvent({ type: 'demoModeChanged', isDemo });

            if (isDemo) {
                this.startDemoTimer();
            } else {
                this.clearDemoTimer();
            }
        }
    }

    public toggleDemoMode(): void {
        this.setDemoMode(!this._isDemo);
    }

    public sendRequestPopup() {
        this.dispatchEvent({ type: 'requestPopup' });
    }

    private startDemoTimer(): void {
        this.clearDemoTimer();
        this.demoTimer = window.setTimeout(() => {
            this.endDemoSession();
        }, this.DEMO_TIMEOUT);
    }

    private clearDemoTimer(): void {
        if (this.demoTimer !== null) {
            window.clearTimeout(this.demoTimer);
            this.demoTimer = null;
        }
    }

    private endDemoSession(): void {
        this.dispatchEvent({ type: 'endWebXRSession' });
        this.sendRequestPopup();
        this.setDemoMode(false);
        this._isWebXRAvailable = false;
        this.dispatchEvent({
            type: 'webXRAvailabilityChanged',
            isAvailable: false,
        });
    }

    public onWebXRSessionStart(): void {
        if (this._isDemo) {
            this.startDemoTimer();
        }
    }

    public onWebXRSessionEnd(): void {
        this.clearDemoTimer();
    }

    public resetWebXRAvailability(): void {
        this._isWebXRAvailable = true;
        this.dispatchEvent({
            type: 'webXRAvailabilityChanged',
            isAvailable: true,
        });
    }
}
