import Camera from '../../Camera';
import Time from '../../Utils/Time';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../../Components/Renderer';
import VrScene, { Rooms } from '../../Components/Three/VrScene';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import {
    Color,
    DoubleSide,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PlaneGeometry,
    Vector3,
    VideoTexture,
    sRGBEncoding,
    Plane,
    Quaternion,
} from 'three';
import Resources from '../../Resources';
import IntersectionContainer from '../../Components/Controllers/IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import LobbyScene from '../LobbyScene/LobbyScene';
import { DashboardNewsSchool } from '../../Components/Ui/DashboardNewsSchool';
import Utils from '../../Utils/Utils';
import DoorView from '../../Components/Doors/Door.view';
import { AXIS, ZoomToView } from '../../Components/ZoomTo/ZoomTo.view';
import TextGenerator from '../../Components/Text/TextGenerator';
import TranslationService from '../../Translations/TranslationService';

@singleton()
@autoInjectable()
export default class SchoolLobby extends VrScene {
    public name = 'SchoolLobby';

    public groupScene;

    public myClassesAndSubjectList: any = [];

    public videoElement: HTMLVideoElement;

    public projector: Mesh;

    public worldModel: Object3D;

    public screenSharing = true;

    public sceneNameForLabel: string = null;

    public constructor(
        public camera?: Camera,
        public time?: Time,
        public renderer?: Renderer,
        public httpClient?: AxiosHttpClient,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
    ) {
        super();
        this.setWorldModel();
        this.sceneNameForLabel = TranslationService.translate(
            'vr.room_label_school_lobby',
        );
    }

    public setWorldModel() {
        this.worldModel = this.resources.items.schoolScene.scene.clone();
        this.worldModel.position.y = -2.5;
        this.add(this.worldModel);
        this.setFloor();

        const geometry = new PlaneGeometry(4, 2.25, 1);
        const material = new MeshBasicMaterial({
            color: 0xffffff,
            side: DoubleSide,
        });
        this.projector = new Mesh(geometry, material);
        this.add(this.projector);
        this.worldModel
            .getObjectByName('Plane054')
            .getWorldPosition(this.projector.position);
        this.projector.translateZ(0.1);
        this.projector.visible = false;

        const roomLabel = new TextGenerator(
            TranslationService.translate('vr.room_label_school_lobby'),
        );
        roomLabel.position.set(5, -2.2, -4.5);
        roomLabel.rotateY(-MathUtils.DEG2RAD * 90);
        this.add(roomLabel);
    }

    public setFloor() {
        const floorGroup = new Object3D();

        floorGroup.name = 'floorGroup';
        // const direction = new PointLight(0xffffff, 0.5);
        // direction.position.y = 5;
        // this.add(direction);

        const mesh = new Mesh(
            new PlaneGeometry(10, 10, 32),
            new MeshPhongMaterial({ color: new Color('red') }),
        );

        mesh.name = 'floor';
        mesh.visible = false;
        mesh.position.y = -2.5;
        mesh.quaternion.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);
        this.addBoundingBox(mesh, false);

        floorGroup.add(mesh);

        this.add(floorGroup);
    }

    public update(time?: Time): void {
        if (
            this.videoElement &&
            this.videoElement.classList.contains('transmiting')
        ) {
            this.projector.visible = true;
        } else if (this.videoElement) {
            this.projector.visible = false;
        }
    }

    public start() {
        this.setTpRings();

        const schoolDashboard = new DashboardNewsSchool();
        this.worldModel
            .getObjectByName('blank_not008')
            .getWorldPosition(schoolDashboard.position);
        schoolDashboard.rotateY(Math.PI / 2);
        schoolDashboard.translateZ(0.1);
        this.add(schoolDashboard);

        const zoomToSchoolDashboard = new ZoomToView({
            target: schoolDashboard.position,
            axis: AXIS.X,
            offset: 2.5,
        });

        zoomToSchoolDashboard.translateX(2.6);
        zoomToSchoolDashboard.translateY(1.2);

        schoolDashboard.add(zoomToSchoolDashboard);

        const zoomToProjector = new ZoomToView({
            target: this.projector.position,
            axis: AXIS.Z,
            offset: 2.5,
        });

        const projectorPosition = this.projector.getWorldPosition(
            new Vector3(),
        );

        zoomToProjector.position.copy(projectorPosition);
        zoomToProjector.translateX(2.6);
        zoomToProjector.translateY(1.2);

        this.add(zoomToProjector);

        const groupDoors = new DoorView({
            group: false,
            doorSpacing: 2,
            localPlanes: [
                new Plane(new Vector3(1, 0, 0), 6),
                new Plane(new Vector3(-1, 0, 0), 2),
            ],
        });
        groupDoors.position.set(-2.2, -1.2, 5.1);

        // groupDoors.position.set(5, -1.4, 2.5);
        groupDoors.rotation.y = MathUtils.degToRad(-180);
        groupDoors.setLocalPlanesToGlobal(
            groupDoors.getWorldPosition(new Vector3()),
            groupDoors.getWorldQuaternion(new Quaternion()),
        );
        groupDoors.updateMatrixWorld();
        this.add(groupDoors);

        const doorToLobby = this.setDoorTo(
            new LobbyScene(),
            this.resources.items.user.id + Rooms.Lobby,
            true,
            'Lobby',
        );

        this.worldModel
            .getObjectByName('Cube023')
            .getWorldPosition(doorToLobby.position);
        doorToLobby.rotateY(-(MathUtils.DEG2RAD * 90));
        this.add(doorToLobby);

        Utils.waitForElm(`[data-user-type="teacher"]`).then(
            (elem: HTMLVideoElement) => {
                this.videoElement = elem;
                const videoTexture = new VideoTexture(elem);
                videoTexture.encoding = sRGBEncoding;
                //@ts-ignore
                this.projector.material = new MeshBasicMaterial({
                    map: videoTexture,
                });

                //@ts-ignore
                this.projector.material.needsUpdate = true;
            },
        );

        Utils.waitForElm(`#video-local`).then((elem: HTMLVideoElement) => {
            this.videoElement = elem;

            const videoTexture = new VideoTexture(elem);
            videoTexture.encoding = sRGBEncoding;

            //@ts-ignore
            this.projector.material = new MeshBasicMaterial({
                map: videoTexture,
            });

            //@ts-ignore
            this.projector.material.needsUpdate = true;
        });
    }
}
