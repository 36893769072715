import envierments from '../../../../Environments/envierments';
import defaultCourseIcon from '@assets/icons/defaultCourseIcon.png';
import model3dicon from '@assets/icons/modelIcon.svg';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MdOutlineExpandLess, MdOutlineExpandMore } from 'react-icons/md';
import { CiSearch } from 'react-icons/ci';
import { FaSearch } from 'react-icons/fa';
import TranslationService from '../../../Translations/TranslationService';

interface Lesson {
    id: string | number;
    title: string;
    other_file_url: string;
    icon_url: string;
}

interface Chapter {
    id: string | number;
    title: string;
    parent_id: string | number | null;
    lessons: Lesson[];
    subChapters: Chapter[];
}

interface CourseData {
    chapters: Chapter[];
}

interface LessonItemProps {
    lesson: Lesson;
    isModelLoaded: (id: string | number) => boolean;
    handleSelectModel: (
        e: React.MouseEvent,
        url: string,
        id: string | number,
        title: string,
        icon: string,
    ) => void;
}

const hasModels3D = (chapter: Chapter): boolean => {
    return (
        chapter.lessons.some((lesson) => lesson.other_file_url) ||
        chapter.subChapters.some(hasModels3D)
    );
};

const LessonItem: React.FC<LessonItemProps> = React.memo(
    ({ lesson, isModelLoaded, handleSelectModel }) => {
        const [isImageLoaded, setIsImageLoaded] = useState(false);
        const imgRef = useRef<HTMLImageElement>(null);

        useEffect(() => {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            setIsImageLoaded(true);
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: '100px' },
            );

            if (imgRef.current) {
                observer.observe(imgRef.current);
            }

            return () => {
                if (imgRef.current) {
                    observer.unobserve(imgRef.current);
                }
            };
        }, []);

        const imageUrl =
            lesson.icon_url === '/media/img/default_course_icon.svg'
                ? defaultCourseIcon
                : `${envierments.baseURL}${lesson.icon_url}`;

        return (
            <div
                className={`model3d-lesson-title ${
                    isModelLoaded(lesson.id) ? 'loaded-model' : ''
                }`}
                id={lesson.id.toString()}
                data-urlmodel={lesson.other_file_url ? 'url-model' : 'none'}
                onClick={(e) =>
                    handleSelectModel(
                        e,
                        lesson.other_file_url,
                        lesson.id,
                        lesson.title,
                        imageUrl,
                    )
                }
            >
                <div ref={imgRef} className="image-container">
                    {isImageLoaded ? (
                        <img
                            src={imageUrl}
                            className="model3d-lesson-icon"
                            alt={lesson.title}
                            loading="lazy"
                        />
                    ) : (
                        <div className="image-placeholder" />
                    )}
                </div>
                <p>{lesson.title}</p>

                {lesson.other_file_url ? (
                    <img
                        className="model3d-icon-svg"
                        src={model3dicon}
                        alt="model-3d-icon"
                    />
                ) : (
                    ''
                )}
            </div>
        );
    },
);
interface ChapterContentProps {
    chapter: Chapter;
    shouldShowLesson: (lesson: Lesson) => boolean;
    isModelLoaded: (id: string | number) => boolean;
    handleSelectModel: LessonItemProps['handleSelectModel'];
}

const ChapterContent: React.FC<ChapterContentProps> = React.memo(
    ({ chapter, shouldShowLesson, isModelLoaded, handleSelectModel }) => {
        if (!chapter.title.trim()) return null;

        const filteredLessons = chapter.lessons.filter(shouldShowLesson);
        const hasContent =
            filteredLessons.length > 0 ||
            chapter.subChapters.some(
                (subChapter) =>
                    subChapter.lessons.some(shouldShowLesson) ||
                    subChapter.subChapters.some(hasModels3D),
            );

        if (!hasContent) return null;

        return (
            <div className="model3d-one-chapter-content">
                <div
                    className="model3d-chapter-subtitle"
                    data-parentid={chapter.parent_id || 'none'}
                >
                    <p>{chapter.title}</p>
                </div>
                {filteredLessons.map((lesson) => (
                    <LessonItem
                        key={lesson.id}
                        lesson={lesson}
                        isModelLoaded={isModelLoaded}
                        handleSelectModel={handleSelectModel}
                    />
                ))}
                {chapter.subChapters.map((subChapter) => (
                    <ChapterContent
                        key={subChapter.id}
                        chapter={subChapter}
                        shouldShowLesson={shouldShowLesson}
                        isModelLoaded={isModelLoaded}
                        handleSelectModel={handleSelectModel}
                    />
                ))}
            </div>
        );
    },
);
interface CourseViewProps {
    courseData: CourseData | null;
    shouldShowLesson: (lesson: Lesson) => boolean;
    isModelLoaded: (id: string | number) => boolean;
    handleSelectModel: LessonItemProps['handleSelectModel'];
}

const CourseView: React.FC<CourseViewProps> = ({
    courseData,
    shouldShowLesson,
    isModelLoaded,
    handleSelectModel,
}) => {
    if (!courseData) return null;

    const [expandedChapters, setExpandedChapters] = useState<{
        [key: string]: boolean;
    }>({});
    const [searchTerm, setSearchTerm] = useState('');

    const toggleChapter = (chapterId: number | string) => {
        setExpandedChapters((prev) => ({
            ...prev,
            [chapterId]: !prev[chapterId],
        }));
    };

    const filteredLessons = useMemo(() => {
        if (!searchTerm.trim()) return null;

        const allLessons: Lesson[] = [];
        const searchLessons = (chapter: Chapter) => {
            chapter.lessons.forEach((lesson) => {
                if (
                    lesson.title
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) &&
                    shouldShowLesson(lesson)
                ) {
                    allLessons.push(lesson);
                }
            });
            chapter.subChapters.forEach(searchLessons);
        };
        courseData.chapters.forEach(searchLessons);
        return allLessons;
    }, [courseData, searchTerm, shouldShowLesson]);

    const renderChapter = (chapter: Chapter) => {
        const hasContent =
            chapter.lessons.some(shouldShowLesson) ||
            chapter.subChapters.some(
                (subChapter) =>
                    subChapter.lessons.some(shouldShowLesson) ||
                    subChapter.subChapters.some(hasModels3D),
            );

        if (!hasContent) return null;

        return (
            <div key={chapter.id}>
                <div
                    className="model3d-chapter-title"
                    data-parentid={chapter.parent_id || 'none'}
                    onClick={() => toggleChapter(chapter.id)}
                >
                    <p>{chapter.title}</p>
                    <button>
                        {expandedChapters[chapter.id] ? (
                            <MdOutlineExpandLess />
                        ) : (
                            <MdOutlineExpandMore />
                        )}
                    </button>
                </div>
                {expandedChapters[chapter.id] && (
                    <ChapterContent
                        chapter={chapter}
                        shouldShowLesson={shouldShowLesson}
                        isModelLoaded={isModelLoaded}
                        handleSelectModel={handleSelectModel}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="model3d-panel-container">
            <div className="search-model-3d-container">
                <input
                    type="text"
                    className="search-model-3d"
                    placeholder={TranslationService.translate(
                        'vr.search-model',
                    )}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <div className="search-icon">
                    <FaSearch />
                </div>
            </div>
            <div className="model3d-panel-items">
                {searchTerm.trim()
                    ? filteredLessons?.map((lesson) => (
                          <LessonItem
                              key={lesson.id}
                              lesson={lesson}
                              isModelLoaded={isModelLoaded}
                              handleSelectModel={handleSelectModel}
                          />
                      ))
                    : courseData.chapters.map(renderChapter)}
            </div>
        </div>
    );
};
export default React.memo(CourseView);
