import React, { useEffect, useState } from 'react';
import AxiosHttpClient from '../../../Network/AxiosHttpClient';
import { container } from 'tsyringe';
import ColyseusClient from '../../../Network/ColyseusClient';
import EventBus from '../../../Utils/EventBus';
import TranslationService from '../../../Translations/TranslationService';
import '../main.css';
import PanelHeader from './3d-panel-header';
import { ClipLoader } from 'react-spinners';
import LoadedModels from './loaded-models';
import { useLoadedModelsContext } from '../Services/LoadedModelsContext';
import CourseView from './3d-viewer-componetns';

export function Model3dPanelDetails(props) {
    const httpClient = container.resolve(AxiosHttpClient);
    const colyseusClient = container.resolve(ColyseusClient);
    const eventBus = container.resolve(EventBus);

    const [course, setCourse] = useState();
    const [courseData, setCourseData] = useState(null);
    const [showMask, setShowMask] = useState(true);
    const [showDownloadModel3dMask, setShowDownloadModel3dMask] =
        useState(false);
    const [selectModel, setSelectModel] = useState(null);

    const {
        loadedModels,
        addLoadedModel,
        removeModel,
        resetAllModels,
        handleChangeModel,
        handleLoadModels,
    } = useLoadedModelsContext();

    useEffect(() => {
        const fetchCourse = async () => {
            const fetchData = await httpClient.getCollection(props.courseId);

            if (fetchData.data) {
                setShowMask(false);
                setCourse(fetchData.data);
                let allCourseCollection = fetchData.data;

                const chapterMap = new Map();
                const resourceMap = new Map();

                allCourseCollection.resources =
                    allCourseCollection.resources.filter((resource) => {
                        const tags = resource.tags
                            ? resource.tags.split(',').map((tag) => tag.trim())
                            : [];
                        return (
                            !tags.includes('3dmovie') &&
                            !tags.includes('3dphoto')
                        );
                    });

                allCourseCollection.resources.forEach((resource) => {
                    if (!resourceMap.has(resource.parent_chapter)) {
                        resourceMap.set(resource.parent_chapter, []);
                    }
                    resourceMap.get(resource.parent_chapter).push(resource);
                });

                allCourseCollection.chapters.forEach((chapter) => {
                    chapter.lessons = resourceMap.get(chapter.id) || [];
                    chapter.subChapters = [];
                    chapterMap.set(chapter.id, chapter);
                });

                const parentChapters = allCourseCollection.chapters.filter(
                    (chapter) => {
                        if (chapter.parent_id === null) {
                            return true;
                        } else {
                            const parentChapter = chapterMap.get(
                                chapter.parent_id,
                            );
                            if (parentChapter) {
                                parentChapter.subChapters.push(chapter);
                            }
                            return false;
                        }
                    },
                );

                allCourseCollection.chapters = parentChapters;
                setCourseData(allCourseCollection);
            }
        };
        fetchCourse().catch(console.error);

        eventBus.addEventListener('model3dAddedToScene', () => {
            setShowDownloadModel3dMask(false);
        });

        eventBus.addEventListener('model3dRemovedFromScene', () => {
            setShowDownloadModel3dMask(false);
        });
    }, []);

    const handleSelectModel = (
        e: React.MouseEvent,
        url: string,
        modelId: number,
        lessonName: string,
        icon: string,
    ) => {
        setShowDownloadModel3dMask(true);

        if (!url || url.length === 0) return;

        const existingModelIndex = loadedModels.findIndex(
            (model) => model.id === modelId,
        );

        if (existingModelIndex !== -1) {
            removeModel(modelId);
        } else {
            addLoadedModel({ id: modelId, url, lessonName, icon });
        }
    };

    const handleLoadModel = (
        url: string,
        modelId: number,
        lessonName: string,
        icon: string,
    ) => {
        setShowDownloadModel3dMask(true);

        if (!url || url.length === 0) return;

        handleChangeModel({
            id: modelId,
            url: url,
            lessonName: lessonName,
            icon: icon,
        });
    };

    const [showOnlyWhereModelIs, setShowOnlyWhereModelIs] = useState(true);

    const Panel3dSettings = () => {
        const handleCheckboxChange = (event) => {
            setShowOnlyWhereModelIs(event.target.checked);
        };

        return (
            <div className="model3d-panel-settings">
                <div className="form-check">
                    <div className="checkbox-wrapper-8">
                        <input
                            className="tgl tgl-skewed"
                            id="cb3-8"
                            checked={showOnlyWhereModelIs}
                            onChange={handleCheckboxChange}
                            type="checkbox"
                        />
                        <label
                            className="tgl-btn"
                            data-tg-off="OFF"
                            data-tg-on="ON"
                            htmlFor="cb3-8"
                        ></label>
                    </div>

                    <p>
                        {TranslationService.translate(
                            'vr.show_only_available_models',
                        )}
                    </p>
                </div>
            </div>
        );
    };

    const shouldShowLesson = (lesson) => {
        return !showOnlyWhereModelIs || lesson.other_file_url;
    };

    const isModelLoaded = (modelId) => {
        return loadedModels.some((model) => model.id === modelId);
    };

    const Loader = () => {
        if (showMask) {
            return (
                <div className="spinner-clip">
                    <ClipLoader
                        color={'#FFF'}
                        loading={showMask}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            );
        }
    };

    return (
        <div className="model3d-panel-details-list">
            <PanelHeader
                backButton={true}
                closeButtonHandler={() => {
                    props.close();
                }}
                backButtonHandler={() => {
                    props.onChange();
                }}
                description={null}
                title={
                    courseData === null
                        ? TranslationService.translate(
                              'vr.find_course_where_model_belongs',
                          )
                        : courseData.title
                }
            />
            {/*<Panel3dSettings />*/}
            <LoadedModels />

            <Loader />

            {/*<div className="model3d-panel-course-title">*/}
            {/*    {courseData === null ? '' : courseData.title}*/}
            {/*</div>*/}

            <CourseView
                type="model3d"
                courseData={courseData}
                shouldShowLesson={shouldShowLesson}
                isModelLoaded={isModelLoaded}
                handleSelectModel={handleSelectModel}
                handleLoadModel={handleLoadModel}
            />
        </div>
    );
}
