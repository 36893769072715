import GUI from 'lil-gui';
import adapter from 'webrtc-adapter';
import Time from './Utils/Time';
import Sizes from './Utils/Sizes';
import Resources from './Resources';

import Camera from './Camera';
import { autoInjectable, container, singleton } from 'tsyringe';
import Renderer from './Components/Renderer';
import VrScene, { Rooms } from './Components/Three/VrScene';
import { EventDispatcher, Scene } from 'three';
import AxiosHttpClient from './Network/AxiosHttpClient';
import 'material-icons/iconfont/material-icons.css';
import IntersectionContainer from './Components/Controllers/IntersectionContainer';

import LobbyScene from './Scenes/LobbyScene/LobbyScene';
import ColyseusClient from './Network/ColyseusClient';
import envierments from '../Environments/envierments';
import PrivateRoomScene from './Scenes/PrivateRoom/PrivateRoomScene';
import {
    IConnectionInformation,
    JanusCommunicationService,
} from './Network/Janus/v2/JanusCommunicationService';
import App from './react/app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import RedirectService from './RedirectService';
import SchoolLobby from './Scenes/SchoolLobby/SchoolLobby';
import GroupScene from './Scenes/Group/GroupScene';
import Classroom from './Scenes/Classroom/ClassroomScene';
import UserService from './Components/UserService';
import DemoService from './DemoService';

@singleton()
@autoInjectable()
export default class Application extends EventDispatcher {
    private $canvas: HTMLCanvasElement;

    private config: {
        selectedScene?: string;
        debug?: boolean;
    };

    private debug: any;

    public scene: VrScene | Scene = new Scene();

    public isReady = false;

    public constructor(
        public httpClient?: AxiosHttpClient,
        public renderer?: Renderer,
        public time?: Time,
        public sizes?: Sizes,
        public resources?: Resources,
        public camera?: Camera,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
        public janusCommunicationService?: JanusCommunicationService,
        public redirectService?: RedirectService,
        public demoService?: DemoService,
    ) {
        super();
        window.app = this;
        window['adapter'] = adapter;
    }

    public async init(options: {
        $canvas?: HTMLCanvasElement;
        domContainer: HTMLDivElement;
    }) {
        if (window.skipObtainToken) {
            const fakeRes = {
                data: {
                    token: localStorage.getItem('token'),
                },
            };
            await this.httpClient.setInterceptors(fakeRes);

            this.initializeComponents(options);
        } else {
            await this.obtainToken(options);
        }

        this.resources.on('ready', () => {
            if (!this.isReady) {
                this.onReady();
            }
        });

        this.resources.on('progress', this.onProgress);
    }

    public initializeComponents(options) {
        this.resources.start();
        this.$canvas = options.$canvas;
        this.$canvas.oncontextmenu = () => false;
        this.renderer.init(options.$canvas);
        this.camera.init();
        this.setConfig();
    }

    public async obtainToken(options) {
        try {
            const tokenRes = await this.httpClient.obtainToken();
            await this.httpClient.setInterceptors(tokenRes);

            this.initializeComponents(options);
        } catch (e) {
            console.error(e);
        }
    }

    public onProgress = (percent: any) => {
        if (percent != 100) {
            document.getElementById('progress-bar-value').innerText =
                'Progress... ' + percent.toString() + '/100%';
        } else {
            document
                .getElementById('view-progress-bar')
                .classList.add('hidden');
        }

        console.log(`progress ${percent}/100`);
    };

    private reactInit() {
        const rootElement = document.getElementById('app');
        const root = ReactDOM.createRoot(rootElement);

        root.render(React.createElement(App));
    }

    public log(text: string) {
        console.log(text);
    }

    public onReady = async () => {
        this.isReady = true;
        const userService = container.resolve(UserService);

        this.reactInit();

        await this.janusInit();

        if (
            this.redirectService.isSchoolRedirect() &&
            userService.is_school_member
        ) {
            await this.sceneInit(
                new SchoolLobby(),
                this.resources.items.user.school_id,
                true,
                true,
            );

            this.redirectService.removeRedirectFlag();
        } else {
            await this.sceneInit(
                new LobbyScene(),
                this.resources.items.user.id + 4,
                true,
                false,
            );
            this.redirectService.removeRedirectFlag();
        }
        //
        // setTimeout(() => {
        //     (this.scene as VrScene).changeScene(
        //         new Classroom(),
        //         1234,
        //         true,
        //         'testowa klasa',
        //     );
        // }, 1);

        this.setExitLessonButton();
        this.setDebug();
    };

    private setExitLessonButton() {
        document
            .querySelector('.exit-lesson-button')
            .addEventListener('click', () => {
                document
                    .querySelector('.iframe-wrapper')
                    .classList.remove('active');
                document.querySelector('.main-iframe').setAttribute('src', '');
            });
    }

    private async sceneInit(
        scene: VrScene,
        roomId: number,
        multiplayer: boolean,
        janus: boolean,
    ) {
        this.scene = scene;

        //@ts-ignore
        window.app.scene = this.scene;

        this.scene.add(this.camera.cameraGroup);

        this.renderer.startRenderLoop(this.scene, this.camera.instance);

        //@ts-ignore
        await this.scene.joinRoom(roomId, multiplayer, null, janus);

        //@ts-ignore
        this.scene.start();
    }

    private async janusInit() {
        const connectionInfo: IConnectionInformation = {
            address: envierments.janus,
            publisherId: this.resources.items.user.id,
            display: this.resources.items.user.username,
            id: this.resources.items.user.id,
        };

        await this.janusCommunicationService.initJanus(connectionInfo);

        setTimeout(() => {
            document
                .querySelector('.share-screen')
                .addEventListener('click', async () => {
                    if (this.demoService.isDemo) {
                        this.demoService.sendRequestPopup();
                        return;
                    }

                    document
                        .querySelector('.share-screen')
                        .classList.add('hidden');
                    document
                        .querySelector('.camera-button-off')
                        .classList.remove('hidden');
                    await this.janusCommunicationService.publishScreen();
                });

            document
                .querySelector('.camera-button-off')
                .addEventListener('click', async () => {
                    if (this.demoService.isDemo) {
                        this.demoService.sendRequestPopup();
                        return;
                    }

                    document
                        .querySelector('.camera-button-off')
                        .classList.add('hidden');
                    document
                        .querySelector('.share-screen')
                        .classList.remove('hidden');

                    await this.janusCommunicationService.unpublishScreen();
                });
        }, 0);
    }

    public changeScenePrivateRoom(roomId: number) {
        (this.scene as VrScene).changeScene(
            new PrivateRoomScene(),
            roomId,
            true,
        );
    }

    private setConfig() {
        this.config = {};
        this.config.debug = /#debug/.test(window.location.href);
        const urlParams = new URLSearchParams(window.location.search);

        this.config.selectedScene = urlParams.get('scene');
    }

    private setDebug() {
        if (this.config.debug) {
            this.debug = new GUI({ width: 300 });

            // if (this.config.selectedScene !== null) {
            //     const sceneExists =
            //         this.sceneManager
            //             .getSceneNames()
            //             .filter(
            //                 (sceneName: string) =>
            //                     sceneName === this.config.selectedScene,
            //             ).length > 0;
            //     if (!sceneExists) {
            //         console.error(
            //             `Scene ${this.config.selectedScene} does not exist`,
            //         );
            //         return;
            //     }
            //
            //     this.changeScene(this.config.selectedScene);
            // }

            // const myObject = {
            //     scene: this.scene.name,
            // };

            // this.debug
            //     .add(myObject, 'scene', this.sceneManager.getSceneNames())
            //     .name('Change scene')
            //     .onChange((value) => {
            //         this.changeScene(value);
            //     });
        }
    }
}
