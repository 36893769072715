import Camera from '../../Camera';
import Time from '../../Utils/Time';
import { autoInjectable, singleton } from 'tsyringe';
import Renderer from '../../Components/Renderer';
import VrScene, { Rooms } from '../../Components/Three/VrScene';
import AxiosHttpClient from '../../Network/AxiosHttpClient';
import {
    Color,
    DoubleSide,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    MeshPhongMaterial,
    Object3D,
    PlaneGeometry,
    Vector3,
    VideoTexture,
    sRGBEncoding,
} from 'three';
import Resources from '../../Resources';
import IntersectionContainer from '../../Components/Controllers/IntersectionContainer';
import ColyseusClient from '../../Network/ColyseusClient';
import LobbyScene from '../LobbyScene/LobbyScene';
import Utils from '../../Utils/Utils';
import {
    ERASER_LINE_WIDTH,
    ICtxPath,
    RESET_CANVAS_LINE_WIDTH,
    REST_CANVAS,
    SketchBoard,
} from '../../Components/Ui/SketchBoard';
import { AXIS, ZoomToView } from '../../Components/ZoomTo/ZoomTo.view';
import TextGenerator from '../../Components/Text/TextGenerator';
import Model3dViewer from '../../Components/Model3dViewer';
import { DashboardNewsGroup } from '../../Components/Ui/DashboardNewsGroup';
import SkyboxChanger from '../../Components/SkyboxChanger';
import { IImage } from '../../react/sketchboards/types';

@singleton()
@autoInjectable()
export default class GroupScene extends VrScene {
    public name = 'Group';

    public worldModel: Object3D;
    public sketchBoardModel1: Object3D;
    public sketchBoardModel2: Object3D;
    public sketchBoardModel3: Object3D;

    public projector: Mesh;

    public videoElement: HTMLVideoElement;

    public sketchBoard1: SketchBoard;

    public sketchBoard2: SketchBoard;

    public sketchBoard3: SketchBoard;

    public skyboxChanger: SkyboxChanger;

    public screenSharing = true;

    public constructor(
        public camera?: Camera,
        public time?: Time,
        public renderer?: Renderer,
        public httpClient?: AxiosHttpClient,
        public resources?: Resources,
        public intersectionContainer?: IntersectionContainer,
        public colyseusClient?: ColyseusClient,
        public modelViewer?: Model3dViewer,
    ) {
        super();

        this.worldModel = this.resources.items.groupScene.scene.clone();
        this.worldModel.position.set(0, -2.65, 0);
        this.add(this.worldModel);

        const geometry = new PlaneGeometry(8, 4.5, 1);
        const material = new MeshBasicMaterial({
            color: 0xffffff,
            side: DoubleSide,
        });
        this.projector = new Mesh(geometry, material);

        this.worldModel
            .getObjectByName('Plane001')
            .getWorldPosition(this.projector.position);
        this.projector.translateX(0.1);
        this.projector.rotateY(Math.PI / 2);

        this.add(this.projector);

        this.sketchBoardModel1 = this.resources.items.sketchBoard.scene.clone();
        this.sketchBoardModel1.position.set(-5, -1.2, -10);
        this.sketchBoardModel1.rotateY(MathUtils.DEG2RAD * 270);
        this.sketchBoardModel1.scale.set(0.5, 0.5, 0.5);
        this.add(this.sketchBoardModel1);

        this.sketchBoardModel2 = this.resources.items.sketchBoard.scene.clone();
        this.sketchBoardModel2.position.set(0, -1.2, -10);
        this.sketchBoardModel2.rotateY(MathUtils.DEG2RAD * 270);
        this.sketchBoardModel2.scale.set(0.5, 0.5, 0.5);
        this.add(this.sketchBoardModel2);

        this.sketchBoardModel3 = this.resources.items.sketchBoard.scene.clone();
        this.sketchBoardModel3.position.set(-2, -1.2, 10);
        this.sketchBoardModel3.rotateY(MathUtils.DEG2RAD * 90);
        this.sketchBoardModel3.scale.set(0.5, 0.5, 0.5);
        this.add(this.sketchBoardModel3);
    }

    public onChange(changes: any[]): void {
        changes.forEach((element) => {
            if (element.previousValue === element.value) {
                return;
            }
            switch (element.field) {
                case 'skybox':
                    this.skyboxChanger.setSkyboxFromName(element.value);
                    break;
            }
        });
    }

    public start() {
        this.setTpRings(20, 20, new Vector3(-13, -2.45, -13));

        const roomLabel = new TextGenerator(
            this.roomName.replaceAll(/\s/g, ''),
        );
        roomLabel.setAnchor('RIGHT');
        roomLabel.position.set(10, -2.5, 9.5);
        roomLabel.rotateY(-MathUtils.DEG2RAD * 90);
        this.add(roomLabel);

        // this.modelViewer.position.set(7, 0, -7.5);
        this.modelViewer.position.set(0, 0, 0);
        this.modelViewer.scale.set(2, 2, 2);
        this.add(this.modelViewer);

        const zoomToProjector = new ZoomToView({
            target: this.projector.position,
            axis: AXIS.X,
            offset: 5,
        });

        zoomToProjector.position.copy(this.projector.position);

        zoomToProjector.rotateY(MathUtils.DEG2RAD * 90);
        zoomToProjector.translateX(5);
        zoomToProjector.translateY(2.5);

        this.add(zoomToProjector);

        const groupDashboard = new DashboardNewsGroup();
        this.worldModel
            .getObjectByName('blank_not005')
            .getWorldPosition(groupDashboard.position);
        groupDashboard.rotateY(Math.PI);
        // groupDashboard.translateZ(0.08);
        groupDashboard.translateZ(0.01);
        this.add(groupDashboard);

        const zoomToSchoolDashboard = new ZoomToView({
            target: groupDashboard.position,
            axis: AXIS.Z,
            offset: -2.5,
        });

        zoomToSchoolDashboard.translateX(2.6);
        zoomToSchoolDashboard.translateY(1.2);

        groupDashboard.add(zoomToSchoolDashboard);

        const lobbySceneDoor = this.setDoorTo(
            new LobbyScene(),
            this.resources.items.user.id + Rooms.Lobby,
            true,
        );
        lobbySceneDoor.rotateY(-(MathUtils.DEG2RAD * 90));
        this.worldModel
            .getObjectByName('Cube011')
            .getWorldPosition(lobbySceneDoor.position);
        this.add(lobbySceneDoor);

        this.skyboxChanger = new SkyboxChanger(this);

        this.skyboxChanger.rotateY(Math.PI / 2);
        this.skyboxChanger.position.set(-9, -2, 0);

        this.add(this.skyboxChanger);

        Utils.waitForElm(`[data-user-type="teacher"]`).then(
            (elem: HTMLVideoElement) => {
                this.videoElement = elem;
                const videoTexture = new VideoTexture(elem);
                videoTexture.encoding = sRGBEncoding;
                //@ts-ignore
                this.projector.material = new MeshBasicMaterial({
                    map: videoTexture,
                });

                //@ts-ignore
                this.projector.material.needsUpdate = true;
            },
        );

        Utils.waitForElm(`#video-local`).then((elem: HTMLVideoElement) => {
            this.videoElement = elem;

            const videoTexture = new VideoTexture(elem);
            videoTexture.encoding = sRGBEncoding;

            //@ts-ignore
            this.projector.material = new MeshBasicMaterial({
                map: videoTexture,
            });

            //@ts-ignore
            this.projector.material.needsUpdate = true;
        });

        const floorGroup = new Object3D();

        floorGroup.name = 'floorGroup';
        // const direction = new THREE.PointLight(0xffffff, 0.5);
        // direction.position.y = 5;
        // this.add(new AmbientLight(new Color('white'), 0.5))
        // this.add(direction);

        const mesh = new Mesh(
            new PlaneGeometry(20, 20, 32),
            new MeshPhongMaterial({ color: new Color('pink') }),
        );

        mesh.name = 'floor';
        mesh.visible = false;
        mesh.position.y = -2.5;
        mesh.quaternion.setFromAxisAngle(new Vector3(1, 0, 0), -Math.PI / 2);

        this.addBoundingBox(mesh, false);

        floorGroup.add(mesh);

        this.add(floorGroup);

        this.sketchBoard1 = new SketchBoard();
        this.sketchBoard1.addEventListener('updateSketchBoard', (e: any) => {
            this.colyseusClient.updateSketchBoard(e.data);
        });

        const zoomToSketchboard1 = new ZoomToView({
            target: this.sketchBoard1.position,
            axis: AXIS.Z,
            offset: 2.5,
        });

        zoomToSketchboard1.translateX(2.2);
        zoomToSketchboard1.translateY(1.1);

        this.sketchBoard1.add(zoomToSketchboard1);

        this.sketchBoard1.addEventListener('resetSketchBoard', () => {
            this.colyseusClient.updateSketchBoard([REST_CANVAS]);
        });
        this.sketchBoard1.position.set(-5, -1.2, -10);
        this.add(this.sketchBoard1);

        this.sketchBoard2 = new SketchBoard();
        this.sketchBoard2.addEventListener('updateSketchBoard', (e: any) => {
            this.colyseusClient.updateSketchBoard2(e.data);
        });

        const zoomToSketchboard2 = new ZoomToView({
            target: this.sketchBoard2.position,
            axis: AXIS.Z,
            offset: 2.5,
        });

        zoomToSketchboard2.translateX(2.2);
        zoomToSketchboard2.translateY(1.1);

        this.sketchBoard2.add(zoomToSketchboard2);

        this.sketchBoard2.addEventListener('resetSketchBoard', () => {
            this.colyseusClient.updateSketchBoard2([REST_CANVAS]);
        });
        this.sketchBoard2.position.set(0, -1.2, -10);
        this.add(this.sketchBoard2);

        this.sketchBoard3 = new SketchBoard();
        this.sketchBoard3.addEventListener('updateSketchBoard', (e: any) => {
            this.colyseusClient.updateSketchBoard3(e.data);
        });

        this.sketchBoard3.addEventListener('resetSketchBoard', () => {
            this.colyseusClient.updateSketchBoard3([REST_CANVAS]);
        });
        this.sketchBoard3.position.set(5, -1.2, 10);
        // this.worldModel.getObjectByName('Plane057').getWorldPosition(this.sketchBoard3.position);
        this.sketchBoard3.rotateY(MathUtils.DEG2RAD * 180);
        this.sketchBoard3.translateX(7);

        const zoomToSketchboard3 = new ZoomToView({
            target: this.sketchBoard3.position,
            axis: AXIS.Z,
            offset: -2.5,
        });

        zoomToSketchboard3.translateX(2.2);
        zoomToSketchboard3.translateY(1.1);

        this.sketchBoard3.add(zoomToSketchboard3);
        this.add(this.sketchBoard3);
    }

    public drawImagesToSketchBoards(sketchBoardName: string, images: IImage) {
        switch (sketchBoardName) {
            case 'sketchboard':
                this.drawImage(this.sketchBoard1, images);
                break;
            case 'sketchboard2':
                this.drawImage(this.sketchBoard2, images);
                break;
            case 'sketchboard3':
                this.drawImage(this.sketchBoard3, images);
        }
    }

    public drawPathToSketchBoards(sketchBoardName: string, ctxPaths: ICtxPath) {
        switch (sketchBoardName) {
            case 'sketchboard':
                this.drawLineOnSketchboard(this.sketchBoard1, ctxPaths);
                break;
            case 'sketchboard2':
                this.drawLineOnSketchboard(this.sketchBoard2, ctxPaths);
                break;
            case 'sketchboard3':
                this.drawLineOnSketchboard(this.sketchBoard3, ctxPaths);
        }
    }

    public drawDrawablesToSketchBoards(
        sketchboardName: string,
        allDrawables: any[],
    ) {
        switch (sketchboardName) {
            case 'sketchboard':
                this.drawAllDrawablesOnSketchboard(
                    this.sketchBoard1,
                    allDrawables,
                );
                break;
            case 'sketchboard2':
                this.drawAllDrawablesOnSketchboard(
                    this.sketchBoard2,
                    allDrawables,
                );
                break;
            case 'sketchboard3':
                this.drawAllDrawablesOnSketchboard(
                    this.sketchBoard3,
                    allDrawables,
                );
        }
    }

    public async drawAllDrawablesOnSketchboard(
        sketchboard: SketchBoard,
        drawables: any[],
    ) {
        for (const drawable of drawables) {
            if (drawable.type === 'image') {
                await this.drawImage(sketchboard, drawable.data);
            } else {
                this.drawLineOnSketchboard(sketchboard, drawable.data);
            }
        }
    }

    public drawLineOnSketchboard(
        sketchBoard: SketchBoard,
        ctxPathsToDraw: ICtxPath,
    ) {
        if (ctxPathsToDraw.lineWidth === ERASER_LINE_WIDTH) {
            sketchBoard.setDrawingMode('erase');
        } else {
            sketchBoard.setDrawingMode('drawing');
        }

        if (ctxPathsToDraw.lineWidth === RESET_CANVAS_LINE_WIDTH) {
            sketchBoard.canvasCtx.clearRect(
                0,
                0,
                sketchBoard.canvas.width,
                sketchBoard.canvas.height,
            );
            // this.canvasCtx.fillStyle = '#ffffff';
            sketchBoard.canvasCtx.fillStyle = 'rgba(255, 255, 255, 0)';
            sketchBoard.canvasCtx.fillRect(
                0,
                0,
                sketchBoard.canvas.width,
                sketchBoard.canvas.height,
            );
        }

        sketchBoard.drawingLineOnCanvas(
            ctxPathsToDraw.x1,
            ctxPathsToDraw.y1,
            ctxPathsToDraw.x2,
            ctxPathsToDraw.y2,
            ctxPathsToDraw.color,
        );
    }

    public async drawImage(sketchboard: SketchBoard, image: IImage) {
        await sketchboard.drawImage(image);
    }

    public onJoin(state: any) {}

    public update(time?: Time): void {
        if (
            this.videoElement &&
            this.videoElement.classList.contains('transmiting')
        ) {
            this.projector.visible = true;
        } else if (this.videoElement) {
            this.projector.visible = false;
        }
    }
}
